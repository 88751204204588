import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Button, Spinner, Alert } from 'react-bootstrap';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendrierSeances.css'; // Assurez-vous que ce fichier existe pour des styles personnalisés

// Importer la localisation française pour moment
import 'moment/locale/fr';

const localizer = momentLocalizer(moment);

// Définir la langue de moment à français
moment.locale('fr');

const GestionSeance = ({ userEmail }) => {
  const [seances, setSeances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedRange, setSelectedRange] = useState(null); // État pour la plage sélectionnée

  // Exemple de données de séance (à remplacer par une vraie API)
  const mockSeances = [
    {
      title: 'Séance 1',
      start: new Date(2024, 9, 21, 10, 0, 0),
      end: new Date(2024, 9, 21, 11, 0, 0),
    },
    {
      title: 'Séance 2',
      start: new Date(2024, 9, 22, 14, 0, 0),
      end: new Date(2024, 9, 22, 15, 0, 0),
    },
  ];

  useEffect(() => {
    const loadSeances = async () => {
      setLoading(true);
      try {
        // Simulez le chargement des séances (remplacez par une API réelle)
        setSeances(mockSeances);
      } catch (error) {
        console.error('Erreur lors de la récupération des séances:', error);
        setErrorMessage('Erreur lors de la récupération des séances.');
      } finally {
        setLoading(false);
      }
    };

    loadSeances();
  }, []);

  const handleSelectSlot = ({ start, end }) => {
    // Retirer un jour à la date de fin
    end = moment(end).subtract(1, 'days').toDate();
    setSelectedRange({ start, end });
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Gestion des Séances 1.0</h2>

      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="sr-only">Chargement...</span>
          </Spinner>
        </div>
      ) : (
        <Calendar
          localizer={localizer}
          events={seances}
          startAccessor="start"
          endAccessor="end"
          selectable
          onSelectSlot={handleSelectSlot} // Gère la sélection
          defaultView="month" // Vue par défaut : "Month"
          views={['month']} // Afficher uniquement la vue "Month", masquer "Week", "Day", et "Agenda"
          style={{ height: 500 }}
          dayPropGetter={(date) => {
            // Si une plage est sélectionnée et que cette cellule est dans cette plage, elle sera grisée
            if (selectedRange) {
              const isInRange =
                moment(date).isSameOrAfter(moment(selectedRange.start), 'day') &&
                moment(date).isSameOrBefore(moment(selectedRange.end), 'day');
              if (isInRange) {
                return { style: { backgroundColor: '#e0e0e0' } }; // Applique un fond gris à la plage sélectionnée
              }
            }
            return {};
          }}
        />
      )}

      {selectedRange && (
        <div>
          <p>
            <strong>Plage sélectionnée :</strong>{' '}
            {moment(selectedRange.start).format('LLL')} à {moment(selectedRange.end).format('LLL')}
          </p>
          <Button variant="primary" onClick={() => alert('Valider la séance')}>
            Valider
          </Button>
        </div>
      )}
    </div>
  );
};

export default GestionSeance;
