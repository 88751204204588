import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table, Alert } from 'react-bootstrap';
import axios from 'axios';
import { FaEdit, FaTrashAlt, FaPlus } from 'react-icons/fa';
import NouveauPatientModal from './NouveauPatientModal';

const GestionPatient = ({ userEmail }) => {
  const [patients, setPatients] = useState([]);
  const [patientToDelete, setPatientToDelete] = useState(null);
  const [patientToEdit, setPatientToEdit] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Récupération des patients
  useEffect(() => {
    refreshPatients();
  }, [userEmail]);

  // Fonction pour rafraîchir la liste des patients
  const refreshPatients = async () => {
    try {
      const response = await axios.get('/api/patients', { params: { login: userEmail } });
      setPatients(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des patients:', error);
      setErrorMessage('Erreur lors de la récupération des patients.');
    }
  };

  // Sauvegarde d'un patient (ajout ou mise à jour)
  const handleSavePatient = async (newPatient) => {
    const { nom, prenom, date_arrivee, login } = newPatient;

    if (!nom || !prenom || !date_arrivee || !login) {
      alert('Tous les champs sont obligatoires.');
      return;
    }

    try {
      if (patientToEdit) {
        await axios.put(`/api/patients/${patientToEdit.patient_id}`, newPatient);
      } else {
        await axios.post('/api/patients', newPatient);
      }
      await refreshPatients(); // Rafraîchir la liste des patients après l'ajout ou la mise à jour
      resetForm();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du patient:', error);
      setErrorMessage('Erreur lors de la sauvegarde du patient. Vérifiez les données.');
    }

  };

  // Suppression d'un patient
  const handleDeletePatient = async () => {
    try {
      await axios.delete(`/api/patients/${patientToDelete.patient_id}`);
      await refreshPatients(); // Rafraîchir la liste des patients après suppression
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Erreur lors de la suppression du patient:', error);
      setErrorMessage('Erreur lors de la suppression du patient.');
    }
  };

  // Préparer l'édition d'un patient
  const handleEditPatient = (patient) => {
    setPatientToEdit(patient);
    setShowModal(true); // Ouvrir le modal pour l'édition
  };

  // Réinitialiser le formulaire
  const resetForm = () => {
    setPatientToEdit(null);
    setShowModal(false);
    setErrorMessage('');
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Liste des Patients</h2>

      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      <div className="d-flex justify-content-end mb-3">
        <Button variant="success" onClick={() => setShowModal(true)}>
          <FaPlus /> Nouveau Patient
        </Button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Date d'Arrivée</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {patients.map((patient) => (
            <tr key={patient.patient_id}>
              <td>{patient.nom}</td>
              <td>{patient.prenom}</td>
              <td>{new Date(patient.date_arrivee).toLocaleDateString()}</td>
              <td className="text-center">
                <FaEdit
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                  onClick={() => handleEditPatient(patient)} // Préparer l'édition
                />
                <FaTrashAlt
                  style={{ cursor: 'pointer', color: 'red' }}
                  onClick={() => {
                    setPatientToDelete(patient);
                    setShowDeleteModal(true);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <NouveauPatientModal
        show={showModal}
        handleClose={resetForm}
        userEmail={userEmail}
        handleSavePatient={handleSavePatient}
        patientToEdit={patientToEdit} // Passer le patient à éditer
      />

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer le patient{' '}
          {patientToDelete && `${patientToDelete.nom} ${patientToDelete.prenom}`} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDeletePatient}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GestionPatient;
