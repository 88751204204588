import React, { useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode'; // Assurez-vous que l'importation est correcte

const Login = ({ onSuccess }) => {
  const [errorMessage, setErrorMessage] = useState('');

  const handleSuccess = (credentialResponse) => {
    const token = credentialResponse.credential;
    try {
      const decoded = jwtDecode(token); // Décode le token JWT
      const userEmail = decoded.email;  // Récupère l'email de l'utilisateur
      onSuccess(token, userEmail);      // Passe le token et l'email au composant parent
      setErrorMessage('');              // Réinitialiser les erreurs en cas de succès
    } catch (error) {
      console.error('Erreur lors du décodage du token JWT:', error);
      setErrorMessage('Erreur lors du décodage du token. Veuillez réessayer.');
    }
  };

  const handleError = () => {
    console.log('Erreur de connexion');
    setErrorMessage('Erreur de connexion avec Google. Veuillez réessayer.');
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
      <h1>Connexion</h1>
      <GoogleLogin
        onSuccess={handleSuccess}
        onError={handleError}
      />
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );
};


const handleLogin = async () => {
  const credentialRequestOptions = {
    publicKey: {
      // Spécifiez les détails de votre demande de connexion
      challenge: new Uint8Array([/* Votre challenge */]),
      // Autres options de votre demande
    },
  };

  try {
    const credential = await navigator.credentials.get(credentialRequestOptions);
    
    // Traitez les informations d'identité obtenues
    if (credential) {
      // Utilisez les informations d'identité pour connecter l'utilisateur
      // Par exemple, envoyez-les à votre serveur pour l'authentification
    }
  } catch (error) {
    console.error('Erreur lors de la récupération des identifiants:', error);
  }
};




export default Login;
