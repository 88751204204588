import React from 'react';
import { useNavigate } from 'react-router-dom';

const NoLicense = () => {
  const navigate = useNavigate();

  const handleQuit = () => {
    navigate('/'); // Retour à la page d'accueil
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="text-center">
        <h1 className="mb-4">Licence non valide</h1>
        <p className="mb-4">
          Vous devez acheter une licence pour accéder à cette fonctionnalité.
          <br />
          Contactez le support pour plus d'informations.
        </p>
        <button onClick={handleQuit} className="btn btn-primary">
          Retour à l'accueil
        </button>
      </div>
    </div>
  );
};

export default NoLicense;
