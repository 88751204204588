import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './components/Dashboard';
import Navbar from './components/Navbar';
import GestionPatient from './components/GestionPatient';
import HonoraireSoin from './components/HonoraireSoin';
import GestionMedecin from './components/GestionMedecin';
import GestionSeance from './components/GestionSeance';
import NoLicense from './components/NoLicense';
import ImpressionFeuilleSoin from './components/ImpressionFeuilleSoin';
import {jwtDecode} from 'jwt-decode'; // Correction de l'importation
import axios from 'axios';

const App = () => {
  const [email, setEmail] = useState(null);
  const [hasLicense, setHasLicense] = useState(false);

  // Fonction pour gérer le succès de la connexion
  const handleSuccess = async (token) => {
    try {
      const { email: userEmail } = jwtDecode(token);
      setEmail(userEmail);

      // Vérification de la licence de l'utilisateur
      const { data } = await axios.post('/api/check-license', { email: userEmail });
      setHasLicense(data.hasLicense);
    } catch (error) {
      console.error('Erreur lors de la vérification de la licence ou du décodage du token:', error);
    }
  };

  // Fonction de déconnexion
  const handleLogout = () => {
    setEmail(null);
    setHasLicense(false);
  };

  return (
    <Router>
      {!email ? (
        <Routes>
          <Route path="/" element={<Login onSuccess={handleSuccess} />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      ) : hasLicense ? (
        <>
          <Navbar handleLogout={handleLogout} />
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/gestion-patient" element={<GestionPatient userEmail={email} />} />
            <Route path="/honoraire-soin" element={<HonoraireSoin userEmail={email} />} />
            <Route path="/gestion-medecin" element={<GestionMedecin userEmail={email} />} />
            <Route path="/gestion-seance" element={<GestionSeance userEmail={email} />} />
            <Route path="/impression-feuille-soin" element={<ImpressionFeuilleSoin userEmail={email} />} />
            <Route path="*" element={<Navigate to="/dashboard" />} />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route path="/no-license" element={<NoLicense />} />
          <Route path="*" element={<Navigate to="/no-license" />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;
