import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';

const NouveauMedecinModal = ({ show, handleClose, userEmail, medecinToEdit, handleSaveMedecin }) => {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [code_medecin, setCodeMedecin] = useState(''); // Corrigé pour setCodeMedecin
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (medecinToEdit) {
      setNom(medecinToEdit.nom);
      setPrenom(medecinToEdit.prenom);
      setCodeMedecin(medecinToEdit.code_medecin); // Corrigé pour setCodeMedecin
    }
  }, [medecinToEdit]);

  const handleSave = async () => {
    if (!nom || !prenom || !code_medecin) {
      setErrorMessage('Client : Tous les champs sont obligatoires.');
      return;
    }

    try {
      await handleSaveMedecin({ nom, prenom, code_medecin, login: userEmail });
      handleClose(); // Fermer la modale
      resetForm(); // Réinitialiser le formulaire
    } catch (error) {
      setErrorMessage("Client : Erreur lors de l'ajout du médecin");
    }
  };

  const resetForm = () => {
    setNom('');
    setPrenom('');
    setCodeMedecin(''); // Corrigé pour setCodeMedecin
    setErrorMessage('');
  };

  return (
    <Modal show={show} onHide={() => { handleClose(); resetForm(); }}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un Nouveau Médecin</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Form>
          <Form.Group>
            <Form.Label>Nom</Form.Label>
            <Form.Control 
              type="text" 
              value={nom} 
              onChange={(e) => setNom(e.target.value)} 
              placeholder="Entrez le nom"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Prénom</Form.Label>
            <Form.Control 
              type="text" 
              value={prenom} 
              onChange={(e) => setPrenom(e.target.value)} 
              placeholder="Entrez le prénom"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Code Médecin</Form.Label>
            <Form.Control 
              type="text" 
              value={code_medecin} 
              onChange={(e) => setCodeMedecin(e.target.value)} // Corrigé pour setCodeMedecin
              placeholder="Entrez le Code Médecin"
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { handleClose(); resetForm(); }}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Ajouter
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NouveauMedecinModal;
