import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUser, FaUserMd, FaHandHoldingHeart, FaCalendarAlt, FaFileAlt, FaSignOutAlt, FaBars } from 'react-icons/fa';
import Cookies from 'js-cookie'; // Gestion des cookies

const Navbar = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false); // État pour contrôler la visibilité des vignettes

  const handleLogout = () => {
    Cookies.remove('authToken');
    window.location.href = 'https://soisse.abcdphoto.fr/';
  };

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible); // Inverser la visibilité du menu
  };

  return (
    <div className="container mt-4">
      {/* Header avec le bouton menu aligné à droite */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1 className="mr-auto">Menu</h1>
        <FaBars
          size={30}
          onClick={toggleMenu}
          style={{ cursor: 'pointer' }}
          className="ml-auto"  // Assurez-vous que l'icône hamburger est bien alignée à droite
        />
      </div>

      {/* Affichage conditionnel du menu selon l'état `isMenuVisible` */}
      {isMenuVisible && (
        <div className="row">
          {/* Vignette Gestion Patient */}
          <div className="col-6 col-md-4 mb-4">
            <Link to="/gestion-patient" className="text-decoration-none">
              <div className="card text-center shadow-sm h-100">
                <div className="card-body">
                  <FaUser className="card-icon" />
                  <h5 className="mt-3">Gestion Patient</h5>
                </div>
              </div>
            </Link>
          </div>

          {/* Vignette Gestion Médecin */}
          <div className="col-6 col-md-4 mb-4">
            <Link to="/gestion-medecin" className="text-decoration-none">
              <div className="card text-center shadow-sm h-100">
                <div className="card-body">
                  <FaUserMd className="card-icon" />
                  <h5 className="mt-3">Gestion Médecin</h5>
                </div>
              </div>
            </Link>
          </div>

          {/* Vignette Honoraire Soin */}
          <div className="col-6 col-md-4 mb-4">
            <Link to="/honoraire-soin" className="text-decoration-none">
              <div className="card text-center shadow-sm h-100">
                <div className="card-body">
                  <FaHandHoldingHeart className="card-icon" />
                  <h5 className="mt-3">Honoraire Soin</h5>
                </div>
              </div>
            </Link>
          </div>

          {/* Vignette Gestion Séance */}
          <div className="col-6 col-md-4 mb-4">
            <Link to="/gestion-seance" className="text-decoration-none">
              <div className="card text-center shadow-sm h-100">
                <div className="card-body">
                  <FaCalendarAlt className="card-icon" />
                  <h5 className="mt-3">Gestion Séance</h5>
                </div>
              </div>
            </Link>
          </div>

          {/* Vignette Impression Feuille de Soin */}
          <div className="col-6 col-md-4 mb-4">
            <Link to="/impression-feuille-soin" className="text-decoration-none">
              <div className="card text-center shadow-sm h-100">
                <div className="card-body">
                  <FaFileAlt className="card-icon" />
                  <h5 className="mt-3">Impression Feuille de Soin</h5>
                </div>
              </div>
            </Link>
          </div>

          {/* Vignette Déconnexion */}
          <div className="col-6 col-md-4 mb-4">
            <div className="card text-center shadow-sm h-100" onClick={handleLogout} style={{ cursor: 'pointer' }}>
              <div className="card-body">
                <FaSignOutAlt className="card-icon" />
                <h5 className="mt-3">Déconnexion?</h5>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Navbar;
