import React from 'react';

const ImpressionFeuilleSoin = () => {
    return (
        <div>
            <h2>Impression des Feuilles de Soin</h2>
            <p>Ici, vous pouvez imprimer les feuilles de soin des patients.</p>
        </div>
    );
};

export default ImpressionFeuilleSoin;

