import React, { useState, useEffect } from 'react';
import { Nav, Table, Button, Alert, Modal } from 'react-bootstrap';
import axios from 'axios';
import { FaPlus, FaEdit, FaTrashAlt } from 'react-icons/fa';
import NouveauSoinModal from './NouveauSoinModal';
import './HonoraireSoin.css'; // Assurez-vous d'importer le fichier CSS

const HonoraireSoin = ({ userEmail }) => {
  const [honorairesSoins, setHonorairesSoins] = useState([]);
  const [soinToDelete, setSoinToDelete] = useState(null);
  const [soinToEdit, setSoinToEdit] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeTab, setActiveTab] = useState('actif'); // État pour gérer l'onglet actif

  // Fetch des honoraires et soins
  useEffect(() => {
    refreshHonorairesSoins();
  }, [userEmail]);

  // Fonction pour rafraîchir la liste des soins
  const refreshHonorairesSoins = async () => {
    try {
      const response = await axios.get('/api/honoraires-soins', { params: { login: userEmail } });
      setHonorairesSoins(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des honoraires et soins:', error);
      setErrorMessage('Erreur lors de la récupération des honoraires et soins.');
    }
  };

  // Sauvegarde d'un soin (ajout ou mise à jour)
  const handleSaveSoin = async (newSoin) => {
    const { code_soin, description, honoraire } = newSoin;

    if (!code_soin || !description || !honoraire) {
      setErrorMessage('Tous les champs sont obligatoires.');
      return;
    }

    try {
      if (soinToEdit) {
        await axios.put(`/api/honoraires-soins/${soinToEdit.honoraire_soin_id}`, newSoin);
      } else {
        await axios.post('/api/honoraires-soins', newSoin);
      }
      await refreshHonorairesSoins(); // Rafraîchir la liste après ajout ou mise à jour
      resetForm();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du soin:', error);
      setErrorMessage('Erreur lors de la sauvegarde du soin. Veuillez réessayer.');
    }
  };

  // Suppression d'un soin
  const handleDeleteSoin = async () => {
    try {
      await axios.delete(`/api/honoraires-soins/${soinToDelete.honoraire_soin_id}`);
      await refreshHonorairesSoins(); // Rafraîchir la liste après suppression
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Erreur lors de la suppression du soin:', error);
      setErrorMessage('Erreur lors de la suppression du soin.');
    }
  };

  // Préparer l'édition d'un soin
  const handleEditSoin = (soin) => {
    setSoinToEdit(soin);
    setShowModal(true);
  };

  // Réinitialiser le formulaire
  const resetForm = () => {
    setSoinToEdit(null);
    setShowModal(false);
    setErrorMessage('');
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Liste des Soins</h2>

      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      <div className="d-flex justify-content-end mb-3">
        <Button variant="success" onClick={() => setShowModal(true)}>
          <FaPlus /> Nouveau Soin
        </Button>
      </div>

      <Nav variant="tabs" activeKey={activeTab} onSelect={setActiveTab}>
        <Nav.Item>
          <Nav.Link eventKey="actif">Soins Actifs</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="inactif">Soins Désactivés</Nav.Link>
        </Nav.Item>
      </Nav>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Code Soin</th>
            <th>Description</th>
            <th>Honoraire (€)</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>

        <tbody>
          {honorairesSoins.filter(soin => (activeTab === 'actif' ? soin.is_active : !soin.is_active))
            .map((soin) => (
              <tr key={soin.honoraire_soin_id}>
                <td>{soin.code_soin}</td>
                <td>{soin.description}</td>
                <td>{soin.honoraire} €</td>
                <td className="text-center">
                  <FaEdit
                    style={{ cursor: 'pointer', marginRight: '10px' }}
                    onClick={() => handleEditSoin(soin)} // Préparer l'édition
                  />
                  {activeTab === 'actif' ? null : (
                    <FaTrashAlt
                      style={{ cursor: 'pointer', color: 'red', marginLeft: '10px' }}
                      onClick={() => {
                        setSoinToDelete(soin);
                        setShowDeleteModal(true);
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>

      <NouveauSoinModal
        show={showModal}
        handleClose={resetForm}
        userEmail={userEmail}
        handleSaveSoin={handleSaveSoin}
        soinToEdit={soinToEdit} // Passer le soin à éditer
      />

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer le soin{' '}
          {soinToDelete && `${soinToDelete.code_soin} ${soinToDelete.description}`} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDeleteSoin}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HonoraireSoin;
