import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Table, Alert } from 'react-bootstrap';
import axios from 'axios';
import { FaEdit, FaTrashAlt, FaPlus } from 'react-icons/fa';
import NouveauMedecinModal from './NouveauMedecinModal';

const GestionMedecin = ({ userEmail }) => {
  const [medecins, setMedecins] = useState([]);
  const [medecinToDelete, setMedecinToDelete] = useState(null);
  const [medecinToEdit, setMedecinToEdit] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Récupération des médecins
  useEffect(() => {
    refreshMedecins();
  }, [userEmail]);

  // Fonction pour rafraîchir la liste des médecins
  const refreshMedecins = async () => {
    try {
      const response = await axios.get('/api/medecins', { params: { login: userEmail } });
      setMedecins(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des médecins:', error);
      setErrorMessage('Erreur lors de la récupération des médecins.');
    }
  };

  // Sauvegarde d'un médecin (ajout ou mise à jour)
  const handleSaveMedecin = async (newMedecin) => {
    const { nom, prenom, code_medecin, login } = newMedecin;

    if (!nom || !prenom || !code_medecin || !login) {
      alert('Tous les champs sont obligatoires.');
      return;
    }

    try {
      if (medecinToEdit) {
        await axios.put(`/api/medecins/${medecinToEdit.medecin_id}`, newMedecin);
      } else {
        await axios.post('/api/medecins', newMedecin);
      }
      await refreshMedecins(); // Rafraîchir la liste des médecins après l'ajout ou la mise à jour
      resetForm();
    } catch (error) {
      console.error('Erreur lors de la sauvegarde du médecin:', error);
      setErrorMessage('Erreur lors de la sauvegarde du médecin. Vérifiez les données.');
    }
  };

  // Suppression d'un médecin
  const handleDeleteMedecin = async () => {
    try {
      await axios.delete(`/api/medecins/${medecinToDelete.medecin_id}`);
      await refreshMedecins(); // Rafraîchir la liste des médecins après suppression
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Erreur lors de la suppression du médecin:', error);
      setErrorMessage('Erreur lors de la suppression du médecin.');
    }
  };

  // Préparer l'édition d'un médecin
  const handleEditMedecin = (medecin) => {
    setMedecinToEdit(medecin);
    setShowModal(true); // Ouvrir le modal pour l'édition
  };

  // Réinitialiser le formulaire
  const resetForm = () => {
    setMedecinToEdit(null);
    setShowModal(false);
    setErrorMessage('');
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4">Liste des Médecins</h2>

      {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

      <div className="d-flex justify-content-end mb-3">
        <Button variant="success" onClick={() => setShowModal(true)}>
          <FaPlus /> Nouveau Médecin
        </Button>
      </div>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Code Médecin</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {medecins.map((medecin) => (
            <tr key={medecin.medecin_id}>
              <td>{medecin.nom}</td>
              <td>{medecin.prenom}</td>
              <td>{medecin.code_medecin}</td>
              <td className="text-center">
                <FaEdit
                  style={{ cursor: 'pointer', marginRight: '10px' }}
                  onClick={() => handleEditMedecin(medecin)} // Préparer l'édition
                />
                <FaTrashAlt
                  style={{ cursor: 'pointer', color: 'red' }}
                  onClick={() => {
                    setMedecinToDelete(medecin);
                    setShowDeleteModal(true);
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <NouveauMedecinModal
        show={showModal}
        handleClose={resetForm}
        userEmail={userEmail}
        handleSaveMedecin={handleSaveMedecin}
        medecinToEdit={medecinToEdit} // Passer le médecin à éditer
      />

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer le médecin{' '}
          {medecinToDelete && `${medecinToDelete.nom} ${medecinToDelete.prenom}`} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDeleteMedecin}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GestionMedecin;
