import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';

const NouveauPatientModal = ({ show, handleClose, userEmail, patientToEdit, handleSavePatient }) => {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [date_arrivee, setDateArrivee] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (patientToEdit) {
      setNom(patientToEdit.nom);
      setPrenom(patientToEdit.prenom);
      // Convertir la date au format YYYY-MM-DD
      setDateArrivee(patientToEdit.date_arrivee ? patientToEdit.date_arrivee.slice(0, 10) : '');
    } else {
      resetForm();
    }
  }, [patientToEdit]);

  const handleSave = async () => {
    if (!nom || !prenom || !date_arrivee) {
      setErrorMessage('Client : Tous les champs sont obligatoires.');
      return;
    }

    try {
      await handleSavePatient({
        nom,
        prenom,
        date_arrivee,
        login: userEmail,
      });
      handleClose(); // Fermer la modale
      resetForm(); // Réinitialiser le formulaire
    } catch (error) {
      setErrorMessage("Client : Erreur lors de l'ajout du patient");
    }
  };

  const resetForm = () => {
    setNom('');
    setPrenom('');
    setDateArrivee('');
    setErrorMessage('');
  };

  return (
    <Modal show={show} onHide={() => { handleClose(); resetForm(); }}>
      <Modal.Header closeButton>
        <Modal.Title>Ajouter un Nouveau Patient</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Form>
          <Form.Group>
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              value={nom}
              onChange={(e) => setNom(e.target.value)}
              placeholder="Entrez le nom"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Prénom</Form.Label>
            <Form.Control
              type="text"
              value={prenom}
              onChange={(e) => setPrenom(e.target.value)}
              placeholder="Entrez le prénom"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Date d'Arrivée</Form.Label>
            <Form.Control
              type="date"
              value={date_arrivee}
              onChange={(e) => setDateArrivee(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { handleClose(); resetForm(); }}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleSave}>
          Ajouter
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NouveauPatientModal;
