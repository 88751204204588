import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';

const NouveauSoinModal = ({ show, handleClose, userEmail, soinToEdit, handleSaveSoin }) => {
  const [code_soin, setCodeSoin] = useState('');
  const [description, setDescription] = useState('');
  const [honoraire, setHonoraire] = useState('');
  const [is_active, setIsActive] = useState(true); // État pour gérer l'activation du soin
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (soinToEdit) {
      setCodeSoin(soinToEdit.code_soin);
      setDescription(soinToEdit.description);
      setHonoraire(soinToEdit.honoraire);
      setIsActive(soinToEdit.is_active); // Charger l'état d'activation
    }
  }, [soinToEdit]);


  const handleSave = async () => {
    if (!code_soin || !description || !honoraire) {
        setErrorMessage('Client : Tous les champs sont obligatoires.');
        return;
    }

    try {
        await handleSaveSoin({
            code_soin,
            description,
            honoraire,
            is_active, // Inclure l'état d'activation
            login: userEmail,
        });
        handleClose(); // Fermer la modale
        resetForm(); // Réinitialiser le formulaire
    } catch (error) {
        setErrorMessage("Client : Erreur lors de l'ajout du soin");
    }
  };


  const resetForm = () => {
    setCodeSoin('');
    setDescription('');
    setHonoraire('');
    setIsActive(true); // Réinitialiser à actif
    setErrorMessage('');
  };

  return (
    <Modal show={show} onHide={() => { handleClose(); resetForm(); }}>
      <Modal.Header closeButton>
        <Modal.Title>{soinToEdit ? 'Modifier le Soin' : 'Ajouter un Nouveau Soin'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Form>
          <Form.Group>
            <Form.Label>Code Soin</Form.Label>
            <Form.Control
              type="text"
              value={code_soin}
              onChange={(e) => setCodeSoin(e.target.value)}
              placeholder="Entrez le code soin"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Entrez la description"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Label>Honoraire (€)</Form.Label>
            <Form.Control
              type="number"
              value={honoraire}
              onChange={(e) => setHonoraire(e.target.value)}
              placeholder="Entrez l'honoraire"
            />
          </Form.Group>
          <Form.Group className="mt-3">
            <Form.Check
              type="checkbox"
              label="Actif"
              checked={is_active}
              onChange={(e) => setIsActive(e.target.checked)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => { handleClose(); resetForm(); }}>
          Annuler
        </Button>
        <Button variant="primary" onClick={handleSave}>
          {soinToEdit ? 'Mettre à jour' : 'Ajouter'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NouveauSoinModal;
